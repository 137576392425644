import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import styles from "./modal.module.css"
import { works } from "../../data/work"
import { useEffect } from "react"

const variants = {
  hidden: {
    top: "50%",
    width: 0,
    left: "50%",
    height: 0,
    opacity: 0,
  },
  visible: {
    width: "120%",
    top: "-10%",
    left: "-10%",
    opacity: 1,
    height: "120%",
  },
}

interface Props {
  activeItem: string | null
  closeModal: () => void
}

export function Modal(props: Props) {
  const { activeItem, closeModal } = props

  function closeOnEsc({ key }: KeyboardEvent) {
    if (key === "Escape") {
      closeModal()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", closeOnEsc)

    return () => {
      window.removeEventListener("keydown", closeOnEsc)
    }
  }, [])

  return (
    <AnimatePresence>
      {activeItem && (
        <motion.div
          layout
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.3 }}
          onPointerDown={e => {
            if ((e.target as HTMLDivElement).id === "overlay") {
              return closeModal()
            }
          }}
          id="overlay"
          className={styles.Modal__wrapper}
        >
          <motion.div className={styles.Modal}>
            <video autoPlay width="100%" controls>
              <source src={works[activeItem].videoRef} />
            </video>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>{works[activeItem].title}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={works[activeItem].link}
              >
                Visit site
              </a>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
