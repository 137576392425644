import React from "react"
import { motion } from "framer-motion"
import { SuperLargeHeading } from "../super-large-heading"
import { SegmentLayout } from "../../layouts/segment-layout"
import styles from "./about-me.module.css"
import { Description } from "./description"
import { usePanelStore } from "../../hooks/useStore"
import { AboutMeImage } from "./about-me-image"
import { AccordionWrapper } from "../accordion-wrapper"

export function AboutMe() {
  const { isOpen, togglePanel } = usePanelStore("about")

  return (
    <SegmentLayout color="dark">
      <div className={styles.AboutMe}>
        <SuperLargeHeading
          togglePanel={togglePanel}
          color="light"
          isOpen={isOpen}
          firstWord="About"
        />
        <AccordionWrapper isOpen={isOpen}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.2 },
            }}
            className={styles.AboutMe__content}
          >
            <Description />
            <AboutMeImage />
          </motion.div>
        </AccordionWrapper>
      </div>
    </SegmentLayout>
  )
}
