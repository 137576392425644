const interests = [
  {
    title: "Yoga",
    imgRef: "yoga.jpeg",
    description:
      "You can catch me in my downward dog at one of London's many yoga studios",
  },
  {
    title: "Music",
    imgRef: "music.jpeg",
    description: "I love attending gigs and jamming with friends",
  },
  {
    title: "Calisthenics",
    imgRef: "calisthenics.jpeg",
    description:
      "One of my greatest passions, join me for some handstand practice in Greenwich Park",
  },
  {
    title: "Art",
    imgRef: "art.jpeg",
    description:
      "My weekends are often spent touring the Tate, V&A, the Barbican, or the RA",
  },
  {
    title: "Travelling",
    imgRef: "travelling.jpeg",
    description:
      "When I'm not visiting London, I'm venturing out to other cities in Europe",
  },
]

export default interests
