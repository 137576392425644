export const career = [
  {
    year: "2020 - Present",
    name: "HealthHero 🏥",
    description:
      "I'm tasked with leading the frontend efforts to create a new user interface to improve the lives of our customer-facing team members. I established (and currently run) the frontend-ux guild, a collective of designers and developers across 5+ countries (and countless teams) that come together to share expertise and experience around all things frontend.",
  },
  {
    year: "2020 - 2020",
    name: "YuLife 🦒",
    description:
      "YuLife encourages you to live your best life, by rewarding you for looking after your wellbeing. As well as the benefits that come from leading a healthy life, like improved physical and mental health, you’re also rewarded with yucoin every time you go for walks or practice meditation. This is all managed in their iOS/Android app, built on React Native.",
  },
  {
    year: "2018 - 2020",
    name: "Trint 📝",
    description:
      "Trint is the world's leading audio-to-text transcription platform. I began as employee #20 and left when the team more than tripled. The product team was focused on delivering a reliable, intuitive, and delightful web application to our users. We achieved this through iterative delivery, continuous discovery, and focused goal-setting.",
  },
  {
    year: "2016 - 2018",
    name: "Win Technologies 💰",
    description:
      "My time at Win Technologies was spent as a Web Developer building the Hippodrome casino's website. Using AngularJS, TypeScript, and Sass, we built a sleek and sexy site fitting for a casino situated right at the heart of Leicester Square.",
  },
  {
    year: "2014 - 2016",
    name: "Brockley's Rock 🐟",
    description:
      "Before I started my career in Tech, I was part of the family Fish and Chip shop business (which is still going strong today!). If you’re ever in South-East London and want some award-winning Fish and Chips, then seek out Brockley’s Rock.",
  },
]
