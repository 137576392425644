import React from "react"
import { SuperLargeHeading } from "../super-large-heading"
import { motion } from "framer-motion"
import styles from "./introduction.module.css"
import { IntroductionImage } from "./introduction-image"
import { SegmentLayout } from "../../layouts/segment-layout"
import { usePanelStore } from "../../hooks/useStore"
import { Headline } from "./headline"
import { AccordionWrapper } from "../accordion-wrapper"

export function Introduction() {
  const { isOpen, togglePanel } = usePanelStore("introduction")

  return (
    <SegmentLayout color="light">
      <div className={styles.Introduction}>
        <SuperLargeHeading
          isOpen={isOpen}
          togglePanel={togglePanel}
          color="dark"
          firstWord="Andrico Karoulla"
        />

        <AccordionWrapper isOpen={isOpen}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.2 },
            }}
            className={styles.Introduction__content}
          >
            <Headline />
            <IntroductionImage />
          </motion.div>
        </AccordionWrapper>
      </div>
    </SegmentLayout>
  )
}
