import React from "react"
import { SuperLargeHeading } from "../super-large-heading"
import { SegmentLayout } from "../../layouts/segment-layout"
import { usePanelStore } from "../../hooks/useStore"
import { AccordionWrapper } from "../accordion-wrapper"
import { motion } from "framer-motion"
import styles from "./career.module.css"
import { career } from "../../data/career"

const variants = {
  open: {
    transition: { staggerChildren: 0.5, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export function Career() {
  const { isOpen, togglePanel } = usePanelStore("career")

  return (
    <SegmentLayout color="dark" id="career">
      <SuperLargeHeading
        isOpen={isOpen}
        togglePanel={togglePanel}
        color="light"
        firstWord="Career"
      />
      <AccordionWrapper isOpen={isOpen}>
        <motion.div style={{ color: "white", position: "relative" }}>
          <Line />
          <motion.div
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            variants={variants}
          >
            {career.map(job => {
              return (
                <motion.div
                  variants={itemVariants}
                  key={job.name}
                  style={{ display: "flex", padding: "16px 0" }}
                >
                  <p className={styles.Career__date}>{job.year}</p>
                  <div>
                    <h2>{job.name}</h2>
                    <p>{job.description}</p>
                  </div>
                </motion.div>
              )
            })}
          </motion.div>
        </motion.div>
      </AccordionWrapper>
    </SegmentLayout>
  )
}

export function Line() {
  return (
    <motion.div
      className={styles.Career__line}
      initial={{ height: 0 }}
      animate={{ height: "100%" }}
      transition={{
        duration: 2.5,
      }}
    />
  )
}
