import React from "react"
import styles from "./headline.module.css"

export function Headline() {
  return (
    <div className={styles.Headline}>
      <p>
        Hi! I'm Andrico and welcome to my site, I'm very excited to welcome you
        here. Grab a seat and make yourself comfy. I'll be sharing with you some
        of my proudest works, a little about my job history, and the things I'm
        most passionate about. All you need to do is tap the section you'd like
        to learn more about.
      </p>
      <p>
        Also, there are a handful of easter eggs hidden around the site, I hope
        you find them all!
      </p>
    </div>
  )
}
