import React from "react"

interface Work {
  title: string
  description: React.ReactNode
  link: string
  videoRef: string
}

export const works: Record<string, Work> = {
  "00-cali-skills.png": {
    title: "Cali Skills",
    link: "https://calisthenicsskills.com",
    videoRef:
      "https://cali-skills-video.s3.eu-west-2.amazonaws.com/Cali+Skills.mp4",
    description: (
      <>
        <p>
          A web app that guides you along your bodyweight fitness journey. Cali
          Skills uses video game-like skill trees to make achieving challenging
          exercises fun. We also generate bespoke workouts based on your
          progress, so you don’t have to deal with cookie-cutter workout plans.
        </p>
        <p>
          Cali Skills is a full-stack application that uses React and TypeScript
          on the frontend, with a Node.js and Express backend that reads and
          writes to a Postgres database. The frontend site is deployed to S3,
          while the backend runs on an EC2 instance. There are several lambda
          functions that handle events. Other tech used include Sentry
          (logging), Auth0 (auth), and FastSpring (billing).",
        </p>
      </>
    ),
  },
  "00-handstand-journey.png": {
    title: "Handstand Journey",
    link:
      "https://play.google.com/store/apps/details?id=com.caliskillsapp&hl=en_GB&gl=US",
    videoRef:
      "https://cali-skills-video.s3.eu-west-2.amazonaws.com/Cali+Skills.mp4",
    description: (
      <>
        <p>
          An Android/iOS app that incredible exercises like the Handstand and
          the Handstand Push Up fun and exciting. You can begin your handstand
          journey from home, with no equipment, great for getting fit during
          lockdown.
        </p>
        <p>
          Handstand Journey is a React Native application that requires no
          server. It manages payments via IAPHub, and uses the same testing and
          deployment principles outlined in Callstack's guide to React Native.
        </p>
      </>
    ),
  },

  "01-lydia-site.png": {
    title: "Lydia MacKenzie",
    description: (
      <>
        <p>
          A website to showcase the illustrious career of London-based dancer
          Lydia MacKenzie. We worked closely together to ensure her site is both
          simple and elegant.
        </p>
        <p>
          Lydia’s site was built using Gatsby and uses Netlify to handle hosting
          and continuous deployment. The animations and interactions are handled
          using framer motion.
        </p>
      </>
    ),
    link: "https://lydiamac.com",
    videoRef:
      "https://cali-skills-video.s3.eu-west-2.amazonaws.com/lydidia+video.mp4",
  },
  "02-pride-in-london.png": {
    title: "Pride in London",
    description: (
      <>
        <p>
          Pride in London celebrates the LGBT community, by providing info about
          all of the amazing events going on around the Pride month. Building
          the Pride in London site was a community effort led by both the Pride
          in London organisation and volunteers.
        </p>
        <p>
          The Pride in London application was built using Gatsby, with all the
          event information being handled in a Contentful CMS.
        </p>
      </>
    ),
    link: "https://prideinlondon.org/",
    videoRef: "https://cali-skills-video.s3.eu-west-2.amazonaws.com/pride.mp4",
  },
  "03-other-works.png": {
    title: "Other Works",
    description: (
      <p>
        This is my original portfolio created back in 2016(!). It showcases some
        of my earliest works, such as a real-time chat application, a custom
        video player, a responsive photo gallery, and a data-driven web app
        dashboard.
      </p>
    ),
    link: "https://portfolio-remake.vercel.app",
    videoRef:
      "https://cali-skills-video.s3.eu-west-2.amazonaws.com/Chat+App.mp4",
  },
}
