import React from "react"
import Layout from "../layouts/layout"
import { Introduction } from "../components/introduction/introduction"
import { AboutMe } from "../components/about-me/about-me"
import { SelectedWorks } from "../components/selected-works/selected-works"
import { Career } from "../components/index/career"
import { Interests } from "../components/interests/interests"
import { Contact } from "../components/index/contact"

const IndexPage = () => (
  <Layout title="Andrico Karoulla">
    <Introduction />
    <AboutMe />
    <SelectedWorks />
    <Career />
    <Interests />
    <Contact />
  </Layout>
)

export default IndexPage
