import React from "react"
import styles from "./contact.module.css"
import { SuperLargeHeading } from "../super-large-heading"
import { SegmentLayout } from "../../layouts/segment-layout"
import { SocialIcon } from "react-social-icons"
import { usePanelStore } from "../../hooks/useStore"
import { AccordionWrapper } from "../accordion-wrapper"
import { GooglePlusIcon } from "./google-plus-icon"

export function Contact() {
  const { isOpen, togglePanel } = usePanelStore("contact")

  return (
    <div>
      <SegmentLayout color="secondary">
        <div className={styles.Contact}>
          <SuperLargeHeading
            isOpen={isOpen}
            togglePanel={togglePanel}
            color="light"
            firstWord="Contact"
          />
          <AccordionWrapper isOpen={isOpen}>
            <div className={styles.Contact__content}>
              <div className={styles.Contact__emailWrapper}>
                <p>
                  Thanks for making it this far. I hope you enjoyed my
                  portfolio. You can download my CV{" "}
                  <a href="https://cali-skills-video.s3.eu-west-2.amazonaws.com/cv.pdf">
                    here
                  </a>
                  . Do you have any open roles/projects? Great! Tell me all
                  about them. I’m available for both perm roles and contract
                  work. You can get in touch with me via{" "}
                  <a href="mailto:karoulla.andrico@gmail.com">email</a>.
                </p>
              </div>
              <div className={styles.Contact__socialIcons}>
                <SocialIcon
                  url="https://www.instagram.com/andricokaroulla/"
                  bgColor="f5f5f5"
                />
                <SocialIcon
                  url="https://github.com/andrico1234"
                  bgColor="f5f5f5"
                />
                <SocialIcon
                  url="https://www.linkedin.com/in/andrico-karoulla/"
                  bgColor="f5f5f5"
                />
                <SocialIcon
                  url="https://twitter.com/andricokaroulla?lang=en"
                  bgColor="f5f5f5"
                />

                <GooglePlusIcon />
              </div>
            </div>
          </AccordionWrapper>
        </div>
      </SegmentLayout>
    </div>
  )
}
