import React, { useState } from "react"
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion"
import Img, { FluidObject } from "gatsby-image"
import { works } from "../../data/work"
import styles from "./slideshow.module.css"
import { Modal } from "./modal"
import { SlideIndicator } from "./slide-indicator"

const SWIPE_THRESHOLD = 10000

interface ExtendedFluidObject extends FluidObject {
  originalName: string
}

interface Props {
  slides: ExtendedFluidObject[]
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

export function Slideshow(props: Props) {
  const [[activeIndex, direction], setActiveIndex] = useState([0, -1])
  const [tapPos, setTapPos] = useState(0)
  const [activeItem, setModalVisibility] = useState<string | null>(null)

  const activeImage = props.slides[activeIndex]
  const name = activeImage.originalName
  const { description } = works[name]

  function nextImage() {
    if (activeIndex === props.slides.length - 1) return setActiveIndex([0, 1])
    setActiveIndex([activeIndex + 1, 1])
  }

  function prevImage() {
    if (activeIndex === 0) return setActiveIndex([props.slides.length - 1, -1])
    setActiveIndex([activeIndex - 1, -1])
  }

  function setCurrentImage(i: number) {
    setActiveIndex([i, 1])
  }

  return (
    <AnimateSharedLayout>
      <p>Click on a project to learn more, or drag to scroll</p>
      <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
        <motion.div
          style={{ cursor: "grab" }}
          key={activeIndex}
          drag="x"
          variants={variants}
          custom={direction}
          initial="enter"
          animate="center"
          onTapStart={(_, info) => {
            setTapPos(info.point.x)
          }}
          onTap={(_, info) => {
            const diff = Math.abs(info.point.x - tapPos)

            if (diff < 10) {
              setModalVisibility(name)
            }

            setTapPos(0)
          }}
          exit="exit"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          transition={{
            opacity: { duration: 0.3 },
          }}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = Math.abs(offset.x) * velocity.x

            if (swipe < -SWIPE_THRESHOLD) {
              nextImage()
            } else if (swipe > SWIPE_THRESHOLD) {
              prevImage()
            }
          }}
        >
          <div className={styles.Slide}>
            <motion.div
              whileHover={{
                scale: 1.05,
              }}
              whileTap={{
                scale: 1.1,
              }}
              style={{
                boxShadow: "var(--box-shadow-elevation-1)",
                position: "relative",
              }}
            >
              <Img fluid={props.slides[activeIndex]} draggable={false} />
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
      <SlideIndicator
        activeItem={activeIndex}
        itemCount={props.slides.length}
        onClick={setCurrentImage}
      />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          key={activeIndex}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.Slide__description}
        >
          {description}
        </motion.div>
      </AnimatePresence>
      <Modal
        activeItem={activeItem}
        closeModal={() => setModalVisibility(null)}
      />
    </AnimateSharedLayout>
  )
}
