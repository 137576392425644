import React from "react"
import styles from "./description.module.css"

export function Description() {
  return (
    <div className={styles.Description}>
      <p>
        I’m a frontend developer based in South East London. I’m a huge fan of
        coding and bodyweight fitness, and am often found trying to combine the
        two.
      </p>

      <p>
        I’ve been a software engineer for the last 5 years, and am heavily
        influenced by the product-focused approaches discussed in books like
        Sense and Respond, The Lean Startup, and Don’t Make Me Think.
      </p>
      <p>
        I’m currently improving the tooling for our customer-facing team members
        at HealthHero. 🔧
      </p>
    </div>
  )
}
