import { AnimatePresence, motion } from "framer-motion"
import React from "react"

interface Props {
  children: React.ReactNode
  isOpen: boolean
}

export function AccordionWrapper(props: Props) {
  const { children, isOpen } = props

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          style={{ position: "relative" }}
          key="introduction"
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: "auto",
            opacity: 1,
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          exit={{ height: 0, opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
