import React, { useMemo } from "react"
import { SuperLargeHeading } from "../super-large-heading"
import styles from "./selected-works.module.css"
import { SegmentLayout } from "../../layouts/segment-layout"
import { usePanelStore } from "../../hooks/useStore"
import { AccordionWrapper } from "../accordion-wrapper"
import { graphql, useStaticQuery } from "gatsby"
import { Slideshow } from "./slideshow"
import { motion } from "framer-motion"
import { Query } from "../../models"

export function SelectedWorks() {
  const { isOpen, togglePanel } = usePanelStore("works")
  const { allFile } = useStaticQuery<Query>(query)

  const slides = useMemo(() => {
    return allFile.edges.map(({ node }) => node.childImageSharp.fluid)
  }, [allFile]).sort((a, b) => {
    if (a.originalName < b.originalName) return -1

    return 1
  })

  return (
    <div className={styles.SelectedWorks}>
      <SegmentLayout color="light">
        <SuperLargeHeading
          isOpen={isOpen}
          togglePanel={togglePanel}
          color="dark"
          firstWord="Selected Works"
        />
        <AccordionWrapper isOpen={isOpen}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.2 },
            }}
          >
            <Slideshow slides={slides} />
          </motion.div>
        </AccordionWrapper>
      </SegmentLayout>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "works" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
              originalName
            }
          }
        }
      }
    }
  }
`
