import create from "zustand"
import shallow from "zustand/shallow"
import { navigate } from "gatsby"
import { Panel } from "../models"

type State = {
  openedPanel: Panel | null
  openPanel: (panelId: Panel) => void
}

const useStore = create<State>(set => ({
  openedPanel: null,
  openPanel: panelId => set(() => ({ openedPanel: panelId })),
}))

const selector = (state: State) => ({
  openPanel: state.openPanel,
  openedPanel: state.openedPanel,
})

export function usePanelStore(panelId: Panel) {
  const { openPanel, openedPanel } = useStore(selector, shallow)

  const isOpen = openedPanel === panelId

  return {
    isOpen,
    togglePanel: () => {
      if (!isOpen) {
        return openPanel(panelId)
      }

      return openPanel(null)
    },
  }
}

export default useStore
