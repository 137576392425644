import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import styles from "./super-large-heading.module.css"

interface Props {
  firstWord: string
  togglePanel: VoidFunction
  color: "light" | "dark"
  isOpen: boolean
}

export function SuperLargeHeading(props: Props) {
  const { firstWord, color, togglePanel, isOpen } = props
  const [x, setX] = useState<string | number>("-3.5%")

  useEffect(() => {
    if (isOpen) return setX(0)

    return setX("-3.5%")
  }, [isOpen])

  return (
    <motion.button
      aria-expanded={isOpen}
      onHoverStart={() => {
        if (isOpen) return
        setX(0)
      }}
      initial={{ x }}
      onHoverEnd={() => {
        if (isOpen) return
        setX("-3.5%")
      }}
      animate={{ x }}
      onClick={togglePanel}
      className={`${styles.SuperLargeHeading} ${
        styles[`SuperLargeHeading_${color}`]
      }`}
    >
      <h2>{firstWord}</h2>
    </motion.button>
  )
}
