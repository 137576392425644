import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Tippy from "@tippyjs/react"
import { followCursor } from "tippy.js"
import styles from "./introduction-image.module.css"
import "tippy.js/dist/tippy.css"

export const IntroductionImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile-image.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Tippy
      content={
        <span
          style={{
            fontSize: "32px",
            position: "relative",

            top: "32px",
          }}
          role="img"
        >
          🇮🇸
        </span>
      }
      plugins={[followCursor]}
      followCursor
    >
      <div className={styles.Image}>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </div>
    </Tippy>
  )
}
