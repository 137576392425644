import React from "react"
import classnames from "classnames"
import styles from "./segment-layout.module.css"
import { AnimatePresence } from "framer-motion"

interface Props {
  color: "light" | "dark" | "secondary"
  children: React.ReactNode
}

export function SegmentLayout(props: Props) {
  const { color, children } = props

  return (
    <AnimatePresence>
      <div
        className={classnames(
          styles.SegmentLayout,
          styles[`SegmentLayout_${color}`]
        )}
      >
        <div className={styles.SegmentLayout__innerWrapper}>{children}</div>
      </div>
    </AnimatePresence>
  )
}
