import React from "react"
import Img from "gatsby-image"
import { motion, AnimatePresence } from "framer-motion"
import useCycle from "../../hooks/useCycle"
import { SuperLargeHeading } from "../super-large-heading"
import { SegmentLayout } from "../../layouts/segment-layout"
import { usePanelStore } from "../../hooks/useStore"
import styles from "./interests.module.css"
import { AccordionWrapper } from "../accordion-wrapper"
import interests from "../../data/interests"
import { graphql, useStaticQuery } from "gatsby"
import { Query } from "../../models"

const variants = {
  enter: {
    y: 30,
    opacity: 0,
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    y: -30,
    opacity: 0,
  },
}

const imageVariants = {
  enter: {
    rotate: 30,
  },
  center: {
    rotate: 0,
  },
  exit: {
    rotate: -30,
  },
}

export function Interests() {
  const { isOpen, togglePanel } = usePanelStore("interests")
  const { currentValue, setNextValue } = useCycle(interests, 0)
  const { allFile } = useStaticQuery<Query>(query)
  const images = allFile.edges.map(({ node }) => node.childImageSharp.fluid)

  return (
    <div className={styles.Interests}>
      <SegmentLayout color="light">
        <SuperLargeHeading
          isOpen={isOpen}
          color="dark"
          togglePanel={togglePanel}
          firstWord="Interests"
        />
        <AccordionWrapper isOpen={isOpen}>
          <button
            onClick={() => {
              setNextValue()
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.2 },
              }}
              className={styles.Interests__wrapper}
            >
              <AnimatePresence initial={false} exitBeforeEnter>
                <motion.div
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ duration: 0.25 }}
                  key={currentValue.title}
                  className={styles.Interests__description}
                >
                  <h2 className={styles.Interests__title}>
                    {currentValue.title}
                  </h2>
                  <p>{currentValue.description}</p>
                </motion.div>
              </AnimatePresence>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  className={styles.Interests__imageContainer}
                  variants={imageVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  key={currentValue.title}
                  transition={{ duration: 0.25 }}
                  style={{
                    originX: "-1000px",
                  }}
                >
                  <Img
                    fluid={images.find(
                      img => img.originalName === currentValue.imgRef
                    )}
                  />
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </button>
        </AccordionWrapper>
      </SegmentLayout>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "interests" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
              originalName
            }
          }
        }
      }
    }
  }
`
